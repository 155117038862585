export const environment = {
  production: true,
  urlService: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/relaunch',
  urlServiceUatp: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/UatpConsulta/consulta',
  urlServiceVoid: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/uatp/void',
  urlServiceVoidWP: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/voidWP',
  urlServiceConsultaWP: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/wpConsulta',
  urlAuthorization: 'https://login.microsoftonline.com/397ed031-3935-40b0-9c69-14fd1164db8f/oauth2/token',
  ocpSuscription: '977e822d5e1f4c5da726511529df849b',
  authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJhcGk6Ly8zMGFiN2NhNy0zY2M2LTQ2MTUtOGYxOS1mMmZlMDQ0YWMzMmYiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8zOTdlZDAzMS0zOTM1LTQwYjAtOWM2OS0xNGZkMTE2NGRiOGYvIiwiaWF0IjoxNjU5NTUwODI3LCJuYmYiOjE2NTk1NTA4MjcsImV4cCI6MTY1OTU1NDcyNywiYWlvIjoiRTJaZ1lEaXptanRRdk1udjBIZFp3WFVmQk9ZZUFRQT0iLCJhcHBpZCI6IjMwYWI3Y2E3LTNjYzYtNDYxNS04ZjE5LWYyZmUwNDRhYzMyZiIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzM5N2VkMDMxLTM5MzUtNDBiMC05YzY5LTE0ZmQxMTY0ZGI4Zi8iLCJvaWQiOiIyNjg1MzAyYi05MDAzLTQxM2UtOTcwZi1hMTNkZjAyOTU3OWYiLCJyaCI6IjAuQVNrQU1kQi1PVFU1c0VDY2FSVDlFV1RiajZkOHF6REdQQlZHanhueV9nUkt3eThwQUFBLiIsInN1YiI6IjI2ODUzMDJiLTkwMDMtNDEzZS05NzBmLWExM2RmMDI5NTc5ZiIsInRpZCI6IjM5N2VkMDMxLTM5MzUtNDBiMC05YzY5LTE0ZmQxMTY0ZGI4ZiIsInV0aSI6IjVkaHNVZWhIM0V5OXZRaW9RWnhhQVEiLCJ2ZXIiOiIxLjAifQ.l-OuX0u_Ny8Iyhk8pE3ysqQNi5b4NhInxJHH012ADp_ksCOIOCyTd0D1pUq3qnrtGiwms3JxYdCC2-n_hwkyhmNWRL5I58xc0B1iQ6ghE2_xxYCKxl6J4yA8-Y0teYavNafhaN1zZJ1ri2-2VowZLFIu1HGZ-ygJI_gHHdc11POl6InIkfdzuCekprKIUKhykNxpKqI3BLqlffDQyIZ1fG1H0hIf0ke8Ae6SzEh5wqSdgREO9t_JC18vPcf2e0W4T1bEwGn5na2tIWEHaqLnXAbStq1-xp-6xxyuOMe2CTeasm6vFQFa18ia5kB3ugiEmh9KGGhRfb2HqnmIXfFJIA',
  urlServiceReservasPurgadas: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/purge',
  ocpSuscriptionReservasPurgadas: '3ece5f04c8e94d6c835aaf2aa5c23bab',
  storageToken: 'https://azstacrelanchuseqa.blob.core.windows.net/?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2051-09-08T23:43:18Z&st=2021-09-08T15:43:18Z&spr=https&sig=a0vDjn1Kzi4eXrrsIIqxye%2Fm3rl0Au%2F8KGHzJEofPXc%3D',
  storageContainer: 'reservas-purgadas',
  urlReportingService: 'https://azwsreportqa.avtest.online/reports/report/ReservasPurgadas-QA/TrazaArchivoCargado',
  urlServiceRefundsWP: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/WorldpayRefunds',
  urlServiceGestionModulos: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/gestionModulos',
  urlServiceHistorialLogs: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/historialLogs',
  redirectUrl: window.location.origin,
  clientId: '57e0af4f-d394-4b3b-bd54-6bec0e153168',
  tenantId: 'a2addd3e-8397-4579-ba30-7a38803fc3bf',
  urlServiceVisionReport: 'https://az-am-con-use-qa.azure-api.net/API_ViancaPay/V1/visionReport',
  userExterno: 'admin',
  passwordExterno: 'admin',
  cellpointUser: 'avianca',
  cellpointPassword: 'zf4Bc6$TyF',
  permisosApp: []
};